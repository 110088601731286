.clearfix() {
  &::before,
  &::after {
    content: " ";
    display: table;
  }

  &::after {
    clear: both;
  }
}

.nopadding {
  padding: 0 !important;
  margin: 0 !important;
}

.evalCircle(@bgstart, @bgend, @bxs1, @bxs2) {
  background: linear-gradient(-180deg, @bgstart 0%, @bgend 96%);
  box-shadow:
    0 0 0 2px @bxs1,
    0 0 0 4px @bxs2;
}

.shadowBorder() {
  border-radius: 6px;
  box-shadow:
    0 0 13px 0 rgba(0, 0, 0, 0.07),
    0 1px 2px 0 rgba(0, 0, 0, 0.12);
}

.hidden {
  display: none;
  visibility: hidden;
}

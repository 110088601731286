.stickylist {
  overflow: hidden;
  position: relative;

  ul {
    list-style: none;
    margin    : 0;
    padding   : 0;

    strong {
      border-top: 1px solid #36434c;
      padding   : 10px 0;
    }

    li {
      border-top: 1px solid #ddd;
      padding   : 0;
      padding   : 10px 15px;

      a {
        display: inline-block;
        width  : 100%;
      }

      .circle {
        background-size: cover !important;
        background     : #d67144;
        display        : inline-block;
        height         : 60px;
        overflow       : hidden;
        vertical-align : middle;
        width          : 60px;
      }

      .shop_image {
        background-size: cover !important;
        background     : #d67144;
        float          : left;
        height         : 60px;
        overflow       : hidden;
        width          : 30%;
      }

      .desc {
        color         : #36434c;
        display       : inline-block;
        font-size     : 12px;
        margin        : 0px;
        padding       : 3px 25px 3px 15px;
        vertical-align: middle;
        width         : calc(100% - 60px);

        b {
          font-size: 13px;
        }

        span {
          border-top : 1px solid #cccccc;
          display    : block;
          font-size  : 10px;
          font-weight: 500;
          margin     : 3px 40px 0 0;
          padding-top: 3px;
        }
      }

      .info {
        box-sizing: content-box;
        height    : 25px;
        padding   : 18px 15px;
        position  : absolute;
        right     : 0px;
        top       : 0;
        width     : 25px;
      }

      .indicator {
        background: #ff0000;
        height    : 100%;
        left      : 0px;
        position  : absolute;
        top       : 0;
        width     : 10px;
      }

      .indicator.open {
        background: #00aa00;
      }
    }
  }
}

.stickylist ul {
  font-weight: 300;
  height     : 100%;
  overflow   : auto;
  position   : relative;

  strong {
    background-color: #ffffff;
    display         : block;
    padding         : 10px 15px;

    &:before {
      background-color: #ff0000;
      border-radius   : 50%;
      content         : '';
      float           : left;
      height          : 10px;
      margin          : 4px 10px 0 0;
      width           : 10px;
    }
  }

  strong.open {
    &:before {
      background-color: #00aa00;
    }
  }
}

.stickylist ul li {
  strong {
    background    : #f0f0f0;
    display       : block;
    text-transform: capitalize;
    width         : 100% !important;
    z-index       : 999;
  }
}

.loading {
  text-align: center;
  background: #fff;
  position  : absolute;
  width     : 100%;
  height    : calc(100% - 55px);
  z-index   : 1;

  img {
    display       : inline-block;
    vertical-align: middle;
    margin        : 0 auto;
    width         : 30px;
  }

  &:after {
    content       : '';
    display       : inline-block;
    height        : 100%;
    vertical-align: middle;
  }
}

@-webkit-keyframes rotating /* Safari and Chrome */ {
  from {
    -ms-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -ms-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -webkit-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes rotating {
  from {
    -ms-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -ms-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -webkit-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.loadingAnimation {
  img {
    -webkit-animation: rotating 2s linear infinite;
    -moz-animation: rotating 2s linear infinite;
    -ms-animation: rotating 2s linear infinite;
    -o-animation: rotating 2s linear infinite;
    animation: rotating 2s linear infinite;
  }
}

/* button pulser */
.pulseAnimation {
	animation-name: 'pulse_animation';
	-webkit-animation-name: 'pulse_animation';
	animation-duration: 2000ms;
	-webkit-animation-duration: 2000ms;
	transform-origin:70% 70%;
	-webkit-transform-origin:70% 70%;
	animation-iteration-count: infinite;
	-webkit-animation-iteration-count: infinite;
	animation-timing-function: linear;
	-webkit-animation-timing-function: linear;
}
@-webkit-keyframes pulse_animation {
	0% { -webkit-transform: scale(1); }
	20% { -webkit-transform: scale(1.12); }
	40% { -webkit-transform: scale(1.25); }
	60% { -webkit-transform: scale(1.12); }
	70% { -webkit-transform: scale(1); }
	85% { -webkit-transform: scale(1.15); }
	100% { -webkit-transform: scale(1); }
}
@keyframes pulse_animation {
	0% { -webkit-transform: scale(1); }
	20% { -webkit-transform: scale(1.12); }
	40% { -webkit-transform: scale(1.25); }
	60% { -webkit-transform: scale(1.12); }
	70% { -webkit-transform: scale(1); }
	85% { -webkit-transform: scale(1.15); }
	100% { -webkit-transform: scale(1); }
}

/* pulsation animation */

#pulser { margin: 0px auto; position: relative; }
.pulser { position: relative; }
.pulse {
  position: absolute;
  top: 0;
  left: 0;
  width: 200px;
  height: 200px;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

.pulse1 {
  border: 12px solid #6f94a4;
  -webkit-animation: pulse1 3s linear infinite;
  -moz-animation: pulse1 3s linear infinite;
  animation: pulse1 3s linear infinite;
}

.pulse2 {
  border: 8px solid #6f94a4;
  -webkit-animation: pulse2 4s linear infinite;
  -moz-animation: pulse2 4s linear infinite;
  animation: pulse2 4s linear infinite;
}

.pulse3 {
  border: 4px solid #6f94a4;
  -webkit-animation: pulse3 2s linear infinite;
  -moz-animation: pulse3 2s linear infinite;
  animation: pulse3 2s linear infinite;
}

.pulse4 {
  border: 2px solid #6f94a4;
  -webkit-animation: pulse4 3s linear infinite;
  -moz-animation: pulse4 3s linear infinite;
  animation: pulse4 3s linear infinite;
}

/* moz */
@-moz-keyframes pulse1 {
  0% { -moz-transform: scale(0); opacity: 0; }
  20% { -moz-transform: scale(0); opacity: 0; }
  40% { -moz-transform: scale(.2); opacity: .4; }
  60% { -moz-transform: scale(.4); opacity: .6; }
  100% { -moz-transform: scale(.6); opacity: 0; }
}

@-moz-keyframes pulse2 {
  0% { -moz-transform: scale(0); opacity: 0; }
  25% { -moz-transform: scale(.2); opacity: .7; }
  40% { -moz-transform: scale(.4); opacity: .5; }
  65% { -moz-transform: scale(.6); opacity: .3; }
  100% { -moz-transform: scale(.8); opacity: 0; }
}

@-moz-keyframes pulse3 {
  0% { -moz-transform: scale(.1); opacity: 0; }
  25% { -moz-transform: scale(.3); opacity: .7; }
  50% { -moz-transform: scale(.5); opacity: .5; }
  75% { -moz-transform: scale(.6); opacity: .3; }
  100% { -moz-transform: scale(.66); opacity: 0; }
}

@-moz-keyframes pulse4 {
  0% { -moz-transform: scale(.1); opacity: 0; }
  30% { -moz-transform: scale(.3); opacity: .6; }
  80% { -moz-transform: scale(.6); opacity: .4; }
  100% { -moz-transform: scale(.8); opacity: 0; }
}

/* webkit */
@-webkit-keyframes pulse1 {
  0% { -webkit-transform: scale(0); opacity: 0; }
  20% { -webkit-transform: scale(0); opacity: 0; }
  40% { -webkit-transform: scale(.2); opacity: .4; }
  60% { -webkit-transform: scale(.4); opacity: .6; }
  100% { -webkit-transform: scale(.6); opacity: 0; }
}

@-webkit-keyframes pulse2 {
  0% { -webkit-transform: scale(0); opacity: 0; }
  25% { -webkit-transform: scale(.2); opacity: .7; }
  40% { -webkit-transform: scale(.4); opacity: .5; }
  65% { -webkit-transform: scale(.6); opacity: .3; }
  100% { -webkit-transform: scale(.8); opacity: 0; }
}

@-webkit-keyframes pulse3 {
  0% { -webkit-transform: scale(.1); opacity: 0; }
  25% { -webkit-transform: scale(.3); opacity: .7; }
  50% { -webkit-transform: scale(.5); opacity: .5; }
  75% { -webkit-transform: scale(.6); opacity: .3; }
  100% { -webkit-transform: scale(.66); opacity: 0; }
}

@-webkit-keyframes pulse4 {
  0% { -webkit-transform: scale(.1); opacity: 0; }
  30% { -webkit-transform: scale(.3); opacity: .6; }
  80% { -webkit-transform: scale(.6); opacity: .4; }
  100% { -webkit-transform: scale(.8); opacity: 0; }
}

.s15 { width : 15px; height: 15px; display: inline-block; }
.s20 { width : 20px; height: 20px; display: inline-block; }
.s25 { width : 25px; height: 25px; display: inline-block; }
.s30 { width : 30px; height: 30px; display: inline-block; }
.s35 { width : 35px; height: 35px; display: inline-block; }
.s40 { width : 40px; height: 40px; display: inline-block; }
.s45 { width : 45px; height: 45px; display: inline-block; }
.border1 { border: 1px solid; }
.border3 { border: 3px solid; }
.border5 { border: 5px solid; }

/*** Colours ***/

.dgrey-text { color: #36434c; }		.dgrey-bg { background: #36434c; }
.d2grey-text { color: #3c4a54; }	.d2grey-bg { background: #3c4a54; }
.lgrey-text { color: #75848d; }		.lgrey-bg { background: #75848d; }
.l2grey-text { color: #9dadb9; }	.l2grey-bg { background: #9dadb9; }
.l3grey-text { color: #c9d1d5; }	.l3grey-bg { background: #c9d1d5; }

.forcecolor { color: #673ab7; } 	.forcebg { background: #673ab7 !important; }

.hidden { display: none; }
.hidden2 { display: none !important; }
.abso { position: absolute; }
.clear { clear: both; }
.opacity0 { opacity: 0; }
.centered { margin: 0 auto; display: block; }
.rounded { -webkit-border-radius: 2px; -moz-border-radius: 2px; border-radius: 2px; }
.circle { -webkit-border-radius: 50%; -moz-border-radius: 50%; border-radius: 50%; }
.ucase { text-transform: uppercase; }
.trans05 { -webkit-transition: all .5s; -moz-transition: all .5s; transition: all .5s; }
.trans10 { -webkit-transition: all 1s; -moz-transition: all 1s; transition: all 1s; }
.trans20 { -webkit-transition: all 2s; -moz-transition: all 2s; transition: all 2s; }
.delay10 { -webkit-transition-delay: 1s; -moz-transition-delay: 1s; transition-delay: 1s; }
.tcenter { text-align: center; }
.tright { text-align: right; }
.tleft { text-align: left; }
.fleft { float: left; }
.fright { float: right; }

.icon object, object.icon, svg.icon { width: 25px; height: 25px; max-width: 25px; max-height: 25px; }
.icon img, img.icon { height: 25px; max-width: 25px; }
	img.icon { padding: 6px 4px; }
		img.icon.content-boxed { box-sizing: content-box; }
  img.moreicon { width: 32px; padding: 5px 10px; }

section {
  padding-top: 58px;
  min-height: 100vh;
}

section.noheader {
  padding-top: 0px;
}

/*** Form elements ***/

form { margin: 30px 0; }
form > div { height: 80px; }

input[type=text],
input[type=email],
input[type=submit],
input[type=date],
input[type=time],
input[type=number],
select {
	-webkit-transition: border .3s ease-out; -moz-transition: border .3s ease-out; transition: border .3s ease-out;
	width: 80%; cursor: text; resize: none; -moz-appearance: none; -webkit-appearance: none; outline: none; box-shadow: none;
	border: none; border-bottom: 1px solid #ccc; padding: 8px; font-size: 13px; color: #555; margin: 20px 0 10px;
	background: transparent;
}
input[type=text] + label,
input[type=email] + label,
input[type=number] + label,
select + label,
input[type=email].empt + label {
	-webkit-transition: all .5s; -moz-transition: all .5s; transition: all .5s; transform-origin: 0 0;
	display: block; pointer-events: none; font-size: 13px; color: #777; margin: -40px 0 0 8px;
}
input[type=text]:focus, input[type=number]:focus, input[type=email]:focus {
	border-color: #36434c; border-width: 3px;
}
input[type=text]:valid + label,
input[type=number]:valid + label,
select:valid + label,
input.notvalid + label {
	color: #aaa; text-transform: uppercase;
	-webkit-transform: translateY(-20px) scale(0.75,0.75); transform: translateY(-20px) scale(0.75,0.75);
}
input[type=text]:focus + label,
input[type=number]:focus + label,
select:focus + label,
input[type=email]:focus + label {
	color: #36434c; text-transform: uppercase;
	-webkit-transform: translateY(-20px) scale(0.75,0.75); transform: translateY(-20px) scale(0.75,0.75);
}

textarea {
	-webkit-transition: border .3s ease-out; -moz-transition: border .3s ease-out; transition: border .3s ease-out;
	width: 80%; cursor: text; resize: none; -moz-appearance: none; -webkit-appearance: none; outline: none; box-shadow: none;
	border: none; border-left: 1px solid #ccc; padding: 8px 15px; font-size: 13px; color: #555; background: transparent; min-height: 50px;
}
textarea:focus { border-color: #36434c; border-width: 3px; }

select { cursor: pointer; }
input[type="submit"], a.button {
	-webkit-transition: all .5s; -moz-transition: all .5s; transition: all .5s;
	font-weight: 500; color: #fff; border: none; display: table;
	padding: 15px 25px 15px; width: auto !important; cursor: pointer; border-radius: 2px;
}

.radio, .checkbox { position: relative; display: table; width: 100%; margin: 30px 0 0; }
.radio input[type=radio], .checkbox input[type=checkbox] {
	display: block; opacity: 0; width: 100%; height: 20px; z-index: 999; cursor: pointer;
}
.radio input[type=radio] + label, .checkbox input[type=checkbox] + label {
	-webkit-transition: color 1s; -moz-transition: color 1s; transition: color 1s;
	display: block; margin: -30px 0 -5px 30px; cursor: pointer; color: #999; padding: 10px 0;
}
.radio input[type=radio]:checked + label, .checkbox input[type=checkbox]:checked + label { color: #111; }
.radio input[type=radio] + label:before, .radio input[type=radio] + label:after {
	-webkit-transition: border .5s; -moz-transition: border .5s; transition: all .5s;
	content: ''; position: absolute; width: 20px; height: 20px; border-radius: 50%;
	border: 2px solid #ccc; margin: 0 0 0 -30px; box-sizing: border-box; pointer-events: none;
}
.radio input[type=radio] + label:after {
	-webkit-transition: all .5s; -moz-transition: all .5s; transition: all .5s;
	width: 0; height: 0; border: none; border-radius: 50%; left: 10px; margin: 10px 0 0; background: #36434c;
}
.radio input[type=radio]:checked + label:before { border: 3px solid #36434c; }
.radio input[type=radio]:checked + label:after { width: 8px; height: 8px; left: 6px; margin: 6px 0 0; }
.radio span { color: #aaa; margin: 0 0 15px 30px; display: block; position: relative; }

.checkbox input[type=checkbox] + label { position: relative; }
.checkbox input[type=checkbox] + label:before, .checkbox input[type=checkbox] + label:after {
	-webkit-transition: border .5s; -moz-transition: border .5s; transition: border .5s;
	content: ''; position: absolute; width: 20px; height: 20px; border-radius: 50%;
	border: 2px solid #ccc; margin: -3px 0 0 -30px; box-sizing: border-box;
}
.checkbox input[type=radio] + label:after,
.checkbox input[type=checkbox] + label:after {
	-webkit-transition: color .5s; -moz-transition: color .5s; transition: color .5s; border: none;
	color: transparent; content: '\e800'; font-family: "tick"; font-size: 12px; left: 4px; top: 4px;
}
.checkbox input[type=radio]:checked + label:before,
.checkbox input[type=checkbox]:checked + label:before { border: 7px solid #75848d; }
.checkbox input[type=checkbox]:checked + label:after {  }

input.error, select.error { border-color: red; }
input.error + label, select.error + label, input.empt.error + label {
	font-size: 10px; color: red; transform:translateY(-20px); text-transform: uppercase;
}

/*** GridCMS Framework CSS 1.1 */

/*** TOC ***/
/********************

	1. Reset (line 15)
	2. Base (line 50)
	3. Grid (line 65)
	4. Responsive ()
	5. Extras ()

********************/


/*** RESET ***/

html {
	-ms-text-size-adjust: 100%;
	-webkit-text-size-adjust: 100%; -webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}
body { margin: 0; }
h1, h2, h3, h4, h5, h6, p, blockquote, figure, ol, ul { margin: 0; padding: 0; }
li { display: block; position: relative; }
h1, h2, h3, h4, h5, h6 { font-size: inherit; }
p { margin-bottom: 15px; } p:last-child { margin-bottom: 0; }
a {
	text-decoration: none; color: inherit; position: relative; display: inline-block;
	-webkit-transition: .3s; -moz-transition: .3s; transition: .3s;
}
a:active, a:hover {outline: 0; }
b, strong { font-weight: 500; }
small { font-size: 80%; }
sub, sup { font-size: 75%; line-height: 0; position: relative; vertical-align: baseline; }
sup { top: -0.5em; }
sub { bottom: -0.25em; }
img { max-width: 100%; height: auto; border: 0; }
hr { -moz-box-sizing: content-box; box-sizing: content-box; height: 0; }
button, input, optgroup, select, textarea { color: inherit; font: inherit; margin: 0; outline: none; }
button, select { text-transform: none; }
input[type="search"] {
	-webkit-appearance: textfield; -moz-box-sizing: content-box; -webkit-box-sizing: content-box; box-sizing: content-box;
}
input[type="search"]::-webkit-search-cancel-button, input[type="search"]::-webkit-search-decoration { -webkit-appearance: none; }
fieldset { border: 1px solid #c0c0c0; margin: 0 2px; padding: 0.35em 0.625em 0.75em; }
textarea { overflow: auto; }
table { border-collapse: collapse; border-spacing: 0; }
td, th { padding: 0; }


/*** BASE ***/

/* Reset Box-Sizing */
* {
	-webkit-box-sizing: border-box; -moz-box-sizing: border-box; -ms-box-sizing: border-box; -o-box-sizing: border-box;
	box-sizing: border-box;
}
.container { margin: 0 auto; max-width: 100%; }
.row { margin-left: auto; margin-right: auto; }
.row:after { clear: both; content: ""; display: block; height: 0; line-height: 0; visibility: hidden; }
.row [class*="col"] { float: left; }
.hide { display: none; }
.hiderow .row { display: none; }


/*** GRID ***/

.container { width: 960px; }

.gap36 .row { margin-left: -1.875%; margin-right: -1.875%; }
.gap36 .row [class*="col"] { margin-left: 1.875%; margin-right: 1.875%; }
.gap36 .row .col-1p1 { width: 96.25%; }
.gap36 .row .col-1p2 { width: 46.25%; }
.gap36 .row .col-1p3 { width: 29.583333%; }
.gap36 .row .col-2p3 { width: 62.916667%; }
.gap36 .row .col-1p4 { width: 21.25%; }
.gap36 .row .col-3p4 { width: 71.25%; }
.gap36 .row .col-1p5 { width: 16.25%; }
.gap36 .row .col-2p5 { width: 36.25%; }
.gap36 .row .col-3p5 { width: 56.25%; }
.gap36 .row .col-4p5 { width: 76.25%; }
.gap36 .row .col-1p6 { width: 12.916667%; }
.gap36 .row .col-5p6 { width: 79.583333%; }

.gap24 .row { margin-left: -1.25%; margin-right: -1.25%; }
.gap24 .row [class*="col"] { margin-left: 1.25%; margin-right: 1.25%; }
.gap24 .row .col-1p1 { width: 97.5%; }
.gap24 .row .col-1p2 { width: 47.5%; }
.gap24 .row .col-1p3 { width: 30.833333%; }
.gap24 .row .col-2p3 { width: 64.166667%; }
.gap24 .row .col-1p4 { width: 22.5%; }
.gap24 .row .col-3p4 { width: 72.5%; }
.gap24 .row .col-1p5 { width: 17.5%; }
.gap24 .row .col-2p5 { width: 37.5%; }
.gap24 .row .col-3p5 { width: 57.5%; }
.gap24 .row .col-4p5 { width: 77.5%; }
.gap24 .row .col-1p6 { width: 14.166667%; }
.gap24 .row .col-5p6 { width: 80.833333%; }

.gap12 .row { margin-left: -0.625%; margin-right: -0.625%; }
.gap12 .row [class*="col"] { margin-left: 0.625%; margin-right: 0.625%; }
.gap12 .row .col-1p1 { width: 98.75%; }
.gap12 .row .col-1p2 { width: 48.75%; }
.gap12 .row .col-1p3 { width: 32.083333%; }
.gap12 .row .col-2p3 { width: 65.416667%; }
.gap12 .row .col-1p4 { width: 23.75%; }
.gap12 .row .col-3p4 { width: 73.75%; }
.gap12 .row .col-1p5 { width: 18.75%; }
.gap12 .row .col-2p5 { width: 38.75%; }
.gap12 .row .col-3p5 { width: 58.75%; }
.gap12 .row .col-4p5 { width: 78.75%; }
.gap12 .row .col-1p6 { width: 15.416667%; }
.gap12 .row .col-5p6 { width: 82.083333%; }

.gap0 .row { margin-left: 0%; margin-right: 0%; }
.gap0 .row [class*="col"] { margin-left: 0%; margin-right: 0%; }
.gap0 .row .col-1p1 { width: 100%; }
.gap0 .row .col-1p2 { width: 50%; }
.gap0 .row .col-1p3 { width: 33.333333%; }
.gap0 .row .col-2p3 { width: 66.666667%; }
.gap0 .row .col-1p4 { width: 25%; }
.gap0 .row .col-3p4 { width: 75%; }
.gap0 .row .col-1p5 { width: 20%; }
.gap0 .row .col-2p5 { width: 40%; }
.gap0 .row .col-3p5 { width: 60%; }
.gap0 .row .col-4p5 { width: 80%; }
.gap0 .row .col-1p6 { width: 16.666667%; }
.gap0 .row .col-5p6 { width: 83.333333%; }

.row .boxed { padding: 20px 0; }
.row .boxed > * { padding-left: 15px; padding-right: 20px; }
.row .boxed30 { padding: 30px 0; }
.row .boxed30 > * { padding-left: 30px; padding-right: 30px; }
.row .boxed50 { padding: 50px 0; }
.row .boxed50 > * { padding-left: 50px; padding-right: 50px; }